import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChapters(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/chapters/all", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchChapter(ctx, chapter) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/chapters/" + chapter.id + "/show")
          .then((response) => resolve(response))
          .catch((error) => reject(error.response));
      });
    },
    addChapter(ctx, chapter) {
      return new Promise((resolve, reject) => {
        axios
          .post("admin/chapter/add", chapter)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateChapter(ctx, chapter) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/chapters/${chapter.id}/update`, chapter.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSections(ctx, chapter) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/chapter/${chapter.id}/sections`, { params: chapter })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSection(ctx, section) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/section/${section.id}/show`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSection(ctx, section) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/section/add', section)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSection(ctx, section) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/section/${section.id}/update`, section.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLessons(ctx, section) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/section/${section.id}/lessons`, {params: section})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLesson(ctx, lesson) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/lesson/${lesson.id}/show`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLesson(ctx, lesson) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/lesson/add', lesson)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateLesson(ctx, lesson) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/lesson/${lesson.id}/update`, lesson.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchExercises(ctx, lesson) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/lesson/${lesson.id}/exercises`, {params: lesson})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchExercise(ctx, exercise) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/exercise/${exercise.id}/show`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addExercise(ctx, exercise) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/exercise/add', exercise)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateExercise(ctx, exercise) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/exercise/update`, exercise)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteExercise(ctx, exercise) {
      console.log(exercise)
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/exercise/${exercise}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLessonGuide(ctx, lesson) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/lesson/${lesson}/guide`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLessonGuide(ctx, guide) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/lesson/guide/add', guide)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateLessonGuide(ctx, guide) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/lesson/guide/${guide.id}/update`, guide.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchExerciseGuide(ctx, exercise) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/exercise/${exercise}/guide`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addExerciseGuide(ctx, guide) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/exercise/guide/add', guide)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateExerciseGuide(ctx, guide) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/exercise/guide/${guide.id}/update`, guide.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addExerciseAfterAnotherExercise(ctx, exercise) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/exercise/add/after', exercise)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
};
