<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="per_pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-1"
              variant="primary"
              @click="addExerciseModal = true"
            >
              Add Exercise
            </b-button>
          </b-col>
        </b-row>
      </div>

      <!-- Show Group Exercise Modal -->
      <b-modal
        v-model="showGroupExerciseModal"
        hide-footer
        centered
        size="lg"
        title="Group Exercise"
      >
        <b-row>
          <b-col :cols="hasTranslation ? '6' : '12'">
            <label for="">Exercise Instruction</label>
            <b-card>
              {{ groupExerciseInstruction }}
            </b-card>
            <label for="">Exercise Activity</label>
            <b-card class="p-2" v-html="formatHtml(groupExerciseActivity)">
            </b-card>
            <div class="demo-inline-spacing">
              <span>
                Student response required?
              </span>
              <b-form-radio
                v-model="groupExerciseRequiresResponse"
                name="group-exercise-requires-response"
                value="Yes"
                class="custom-control-primary"
                disabled
              >
                Yes
              </b-form-radio>
              <b-form-radio
                v-model="groupExerciseRequiresResponse"
                name="group-exercise-requires-response"
                value="No"
                class="custom-control-primary"
                disabled
              >
                No
              </b-form-radio>
            </div>
            <br>
            <label v-if="groupExerciseRequiresResponse === 'Yes' && groupExerciseExpectedResponse != null">Expected Student Response</label>
            <b-card class="p-2" v-if="groupExerciseRequiresResponse === 'Yes' && groupExerciseExpectedResponse != null" v-html="formatHtml(groupExerciseExpectedResponse)">
            </b-card>
          </b-col>
          <b-col cols="6" v-if="hasTranslation">
            <label for="">Exercise Instruction Translation</label>
            <b-card>
              {{ groupExerciseInstructionTranslation }}
            </b-card>
            <label for="">Exercise Activity Translation</label>
            <b-card class="p-2" v-html="formatHtml(groupExerciseActivityTranslation)">
            </b-card>
            <br>
            <label v-if="groupExerciseRequiresResponse === 'Yes' && groupExerciseExpectedResponseTranslation != null">Expected Student Response Translation</label>
            <b-card class="p-2" v-if="groupExerciseRequiresResponse === 'Yes' && groupExerciseExpectedResponseTranslation != null" v-html="formatHtml(groupExerciseExpectedResponseTranslation)">
            </b-card>
          </b-col>
        </b-row>
        <exercise-media :exercise="exerciseData" />
      </b-modal>

      <!-- Show Information Exercise -->
      <b-modal
        v-model="showInformationModal"
        hide-footer
        centered
        size="lg"
        title="Information"
      >
        <b-row>
          <b-col :cols="hasTranslation ? '6' : '12'">
            <label for="">Topic</label>
            <b-card>
              {{ informationTopicExercise }}
            </b-card>
            <label for="" v-if="informationSubtopicExercise != ''">Subtopic</label>
            <b-card v-if="informationSubtopicExercise != ''">
              {{ informationSubtopicExercise }}
            </b-card>
            <b-card class="p-2" v-html="formatHtml(informationContent)">
            </b-card>
          </b-col>
          <b-col cols="6" v-if="hasTranslation">
            <label for="">Topic Translation</label>
            <b-card>
              {{ informationTopicExerciseTranslation }}
            </b-card>
            <label for="" v-if="informationSubtopicExerciseTranslation != ''">Subtopic Translation</label>
            <b-card v-if="informationSubtopicExerciseTranslation != ''">
              {{ informationSubtopicExerciseTranslation }}
            </b-card>
            <b-card class="p-2" v-html="formatHtml(informationContentTranslation)">
            </b-card>
          </b-col>
        </b-row>
        <exercise-media :exercise="exerciseData" />
      </b-modal>

      <!-- Show Conversation Exercise -->
      <b-modal
        v-model="showConversationModal"
        hide-footer
        centered
        size="lg"
        title="Conversation"
      >
        <b-row>
          <b-col :cols="hasTranslation ? '6' : '12'">
            <label for="conversation">Conversation</label>
            <b-card class="p-2" v-html="formatHtml(conversationConversation)">
            </b-card>
          </b-col>
          <b-col cols="6" v-if="hasTranslation">
            <label for="conversation">Conversation Translation</label>
            <b-card class="p-2" v-html="formatHtml(conversationConversationTranslation)">
            </b-card>
          </b-col>
        </b-row>
        <exercise-media :exercise="exerciseData" />
      </b-modal>

      <!-- Show Match Word Exercise -->
      <b-modal
        v-model="showMatchWordModal"
        hide-footer
        centered
        size="lg"
        title="Match Word"
      >
        <b-row>
          <b-col :cols="hasTranslation ? '6' : '12'">
            <b-card
              title="Instruction"
            >
              <b-card-text>{{ matchWordInstruction }}.</b-card-text>
            </b-card>
            <b-card title="Words">
              <b-badge
                v-for="word in matchWordWords" :key="word"
                pill
                variant="light-secondary"
                class="text-capitalize"
              >
                {{ word }}
              </b-badge>
            </b-card>
            <b-card title="Options">
              <b-badge
                v-for="option in matchWordOptions" :key="option"
                pill
                variant="light-warning"
                class="text-capitalize"
              >
                {{ option }}
              </b-badge>
            </b-card>
            <b-card title="Answer">
              <b-badge
                v-for="answer in matchWordAnswer" :key="answer"
                pill
                variant="light-warning"
                class="text-capitalize"
              >
                {{ answer }}
              </b-badge>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card
              title="Instruction Translation"
            >
              <b-card-text>{{ matchWordInstructionTranslation }}.</b-card-text>
            </b-card>
            <b-card title="Words">
              <b-badge
                v-for="word in matchWordWordsTranslation" :key="word"
                pill
                variant="light-secondary"
                class="text-capitalize"
              >
                {{ word }}
              </b-badge>
            </b-card>
            <b-card title="Options Translation">
              <b-badge
                v-for="option in matchWordOptionsTranslation" :key="option"
                pill
                variant="light-warning"
                class="text-capitalize"
              >
                {{ option }}
              </b-badge>
            </b-card>
            <b-card title="Answer(s) Translation">
              <b-badge
                v-for="answer in matchWordAnswerTranslation" :key="answer"
                pill
                variant="light-warning"
                class="text-capitalize"
              >
                {{ answer }}
              </b-badge>
            </b-card>
          </b-col>
        </b-row>
        <exercise-media :exercise="exerciseData" />
      </b-modal>

      <!-- Show Question Exercise -->
      <b-modal
        v-model="showQuestionModal"
        hide-footer
        centered
        size="lg"
        title="Question"
      >
        <b-row>
          <b-col :cols="hasTranslation ? '6' : '12'">
            <b-card
              title="Question"
            >
              <b-card-text>{{ questionQuestion }}.</b-card-text>
            </b-card>
            <b-card title="Words">
              <b-badge
                v-for="option in questionOptions" :key="option"
                pill
                variant="light-secondary"
                class="text-capitalize"
              >
                {{ option }}
              </b-badge>
            </b-card>
            <b-card title="Answer">
              <b-badge
                v-for="answer in questionAnswer" :key="answer"
                pill
                variant="light-warning"
                class="text-capitalize"
              >
                {{ answer }}
              </b-badge>
            </b-card>
          </b-col>
          <b-col cols="6" v-if="hasTranslation">
            <b-card
              title="Question Translation"
            >
              <b-card-text>{{ questionQuestionTranslation }}</b-card-text>
            </b-card>
            <b-card title="Words Translation">
              <b-badge
                v-for="option in questionOptionsTranslation" :key="option"
                pill
                variant="light-secondary"
                class="text-capitalize"
              >
                {{ option }}
              </b-badge>
            </b-card>
            <b-card title="Answer(s) Translation">
              <b-badge
                v-for="answer in questionAnswerTranslation" :key="answer"
                pill
                variant="light-warning"
                class="text-capitalize"
              >
                {{ answer }}
              </b-badge>
            </b-card>
          </b-col>
        </b-row>
        <exercise-media :exercise="exerciseData" />
      </b-modal>

      <!-- Add Exercise Modal -->
      <b-modal
        v-model="addExerciseModal"
        hide-footer
        centered
        size="lg"
        title="Add Exercise"
      >
        <span>Select Exercise Type</span>
        <div class="demo-inline-spacing mb-1">
          <b-form-radio
            v-model="addExerciseType"
            name="group-exercise"
            value="Group Exercise"
            class="custom-control-primary"
          >
            Group Exercise
          </b-form-radio>
          <b-form-radio
            v-model="addExerciseType"
            name="information-type"
            value="Information"
            class="custom-control-primary"
          >
            Information
          </b-form-radio>
          <b-form-radio
            v-model="addExerciseType"
            name="conversation-type"
            value="Conversation"
            class="custom-control-primary"
          >
            Conversation
          </b-form-radio>
          <b-form-radio
            v-model="addExerciseType"
            name="match-word-type"
            value="Match Word"
            class="custom-control-primary"
          >
            Match Words
          </b-form-radio>
          <b-form-radio
            v-model="addExerciseType"
            name="question-type"
            value="Question"
            class="custom-control-primary"
          >
            Question
          </b-form-radio>
        </div>
        <!-- Group Exercise Form -->
        <div v-if="addExerciseType === 'Group Exercise'">
          <b-form-group label="Instruction" label-for="enterInstruction">
            <b-form-input
              id="enterInstruction"
              placeholder="Enter the Instructions for this exercise"
              v-model="addGroupExerciseInstruction"
            />
          </b-form-group>
          <b-form-group label="Group Exercise" label-for="groupExercise">
            <quill-editor
              v-model="addGroupExerciseActivity"
              :options="groupExerciseEditorOption"
            />
          </b-form-group>
          <b-row>
            <b-col lg="4">
              <div class="demo-inline-spacing mb-1">
                <span>Requires Student Response?</span>
                <b-form-radio
                  v-model="addGroupExerciseRequiresResponse"
                  name="requires-response-yes"
                  value="Yes"
                  class="custom-control-primary"
                >
                  Yes
                </b-form-radio>
                <b-form-radio
                  v-model="addGroupExerciseRequiresResponse"
                  name="requires-response-no"
                  value="No"
                  class="custom-control-primary"
                >
                  No
                </b-form-radio>
              </div>
            </b-col>
            <b-col lg="8">
              <b-form-group v-if="addGroupExerciseRequiresResponse == 'Yes'" label="Expected Response" label-for="expectedResponse">
                <b-form-input
                  id="enterExpectedResponse"
                  placeholder="Enter the expected input for this exercise"
                  v-model="addGroupExerciseExpectedResponse"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <!-- Information Exercise Form -->
        <div v-if="addExerciseType === 'Information'">
          <b-form-group label="Topic *" label-for="enterTopic">
            <b-form-input
              id="enterTopic"
              placeholder="Enter the Topic for this information"
              v-model="addInformationTopic"
            />
          </b-form-group>
          <b-form-group label="Subtopic" label-for="enterSubtopic">
            <b-form-input
              id="enterSubtopic"
              placeholder="Enter the Subtopic for this information"
              v-model="addInformationSubtopic"
            />
          </b-form-group>

          <b-form-group label="Information *" label-for="information">
            <quill-editor
              v-model="addInformationContent"
              :options="informationEditorOption"
            />
          </b-form-group>
        </div>

        <!-- Conversation Exercise Form -->
        <div v-if="addExerciseType === 'Conversation'">
          <b-form-group label="Conversation *" label-for="conversation">
            <quill-editor
              v-model="addConversation"
              :options="conversationEditorOption"
            />
          </b-form-group>
        </div>

        <!-- Match Word Exercise Form -->
        <div v-if="addExerciseType === 'Match Word'">
          <b-form-group label="Enter Instruction for this exercise *" label-for="enterMatchWordsInstruction">
            <b-form-input
              id="enterMatchWords"
              placeholder="Enter Words to Match"
              v-model="addMatchWordInstruction"
            />
          </b-form-group>
          <b-form-group label="Enter Words to Match (separated by a comma ',') *" label-for="enterMatchWords">
            <b-form-input
              id="enterMatchWords"
              placeholder="Enter Words to Match"
              v-model="addMatchWordWords"
            />
          </b-form-group>
          <b-form-group label="Enter Options (separated by a comma ',')" label-for="enterOptions">
            <b-form-input
              id="enterOptions"
              placeholder="Enter the Options"
              v-model="addMatchWordOptions"
            />
          </b-form-group>
          <b-form-group label="Enter the answer (separated by a comma ',')" label-for="enterAnswer">
            <b-form-input
              id="enterAnswer"
              placeholder="Enter the Answer"
              v-model="addMatchWordAnswer"
            />
          </b-form-group>
        </div>

        <!-- Question Exercise Form -->
        <div v-if="addExerciseType === 'Question'">
          <b-form-group label="Enter the Question *" label-for="enterQuestion">
            <b-form-input
              id="enterQuestion"
              placeholder="Enter the question"
              v-model="addQuestion"
            />
          </b-form-group>
          <b-form-group label="Enter Options (separated by a comma ',')" label-for="enterOptions">
            <b-form-input
              id="enterOptions"
              placeholder="Enter the Options"
              v-model="addQuestionOptions"
            />
          </b-form-group>
          <b-form-group label="Enter the answer (separated by a comma ',')" label-for="enterAnswer">
            <b-form-input
              id="enterAnswer"
              placeholder="Enter the Answer"
              v-model="addQuestionAnswer"
            />
          </b-form-group>
        </div>

        <!-- Add Exercise Image Field -->
        <b-form-group label="Select Exercise's Image" label-for="selectFile">
          <b-form-file
            v-model="addExerciseImage"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg,.jpeg,.png"
          />

          <b-card-text class="my-1">
            Selected image: <strong>{{ addExerciseImage ? addExerciseImage.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <!-- Add Exercise Audio Field -->
        <b-form-group label="Select Exercise's Audio" label-for="selectFile">
          <b-form-file
            v-model="addExerciseAudio"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".mp3,.wav,.m4a"
          />

          <b-card-text class="my-1">
            Selected audio: <strong>{{ addExerciseAudio ? addExerciseAudio.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <!-- Add Exercise Video Field -->
        <b-form-group label="Select Exercise's Video" label-for="selectFile">
          <b-form-file
            v-model="addExerciseVideo"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".mp4"
          />

          <b-card-text class="my-1">
            Selected video: <strong>{{ addExerciseVideo ? addExerciseVideo.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add Translation? </span>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <!-- Exercise Translation -->
        <div v-if="addTranslation === 'yes'">
          <!-- Group Exercise Form -->
          <div v-if="addExerciseType === 'Group Exercise'">
            <b-form-group label="Instruction Translation" label-for="enterInstructionTranslation">
              <b-form-input
                id="enterInstructionTranslation"
                placeholder="Enter the Tranlsated Instructions for this exercise"
                v-model="addGroupExerciseInstructionTranslation"
              />
            </b-form-group>
            <b-form-group label="Group Exercise Translation" label-for="groupExerciseTranslation">
              <quill-editor
                v-model="addGroupExerciseActivityTranslation"
                :options="groupExerciseEditorOption"
              />
            </b-form-group>
            <b-row>
              <b-col lg="8">
                <b-form-group v-if="addGroupExerciseRequiresResponse === 'Yes'" label="Expected Response Translation (optional)" label-for="expectedResponse">
                  <b-form-input
                    id="enterExpectedResponseTranslation"
                    placeholder="Enter the translated expected input for this exercise"
                    v-model="addGroupExerciseExpectedResponseTranslation"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <!-- Information Exercise Form -->
          <div v-if="addExerciseType === 'Information'">
            <b-form-group label="Topic Translation *" label-for="enterTopicTranslation">
              <b-form-input
                id="enterTopicTranslation"
                placeholder="Enter the Tranlation of the Topic for this information"
                v-model="addInformationTopicTranslation"
              />
            </b-form-group>
            <b-form-group label="Subtopic Translation" label-for="enterSubtopicTranslation">
              <b-form-input
                id="enterSubtopicTranslation"
                placeholder="Enter the translated Subtopic for this information"
                v-model="addInformationSubtopicTranslation"
              />
            </b-form-group>

            <b-form-group label="Information Translation *" label-for="informationTranslation">
              <quill-editor
                v-model="addInformationContentTranslation"
                :options="informationEditorOption"
              />
            </b-form-group>
          </div>

          <!-- Conversation Exercise Form -->
          <div v-if="addExerciseType === 'Conversation'">
            <b-form-group label="Conversation Translation *" label-for="conversationTranslation">
              <quill-editor
                v-model="addConversationTranslation"
                :options="conversationEditorOption"
              />
            </b-form-group>
          </div>

          <!-- Match Word Exercise Form -->
          <div v-if="addExerciseType === 'Match Word'">
            <b-form-group label="Enter Translated Instruction for this exercise *" label-for="enterMatchWordsInstructionTranslation">
              <b-form-input
                id="enterMatchWordsTranslation"
                placeholder="Enter Translated Words to Match"
                v-model="addMatchWordInstructionTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter Translated Words to Match (separated by a comma ',') *" label-for="enterMatchWordsTranslated">
              <b-form-input
                id="enterMatchWordsTranslation"
                placeholder="Enter Translated Words to Match"
                v-model="addMatchWordWordsTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter Translated Options (separated by a comma ',')" label-for="enterOptionsTranslation">
              <b-form-input
                id="enterOptionsTranslation"
                placeholder="Enter the Translated Options"
                v-model="addMatchWordOptionsTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter the translated answer(s) (separated by a comma ',')" label-for="enterAnswerTranslation">
              <b-form-input
                id="enterAnswerTranslation"
                placeholder="Enter the Translated Answer(2)"
                v-model="addMatchWordAnswerTranslation"
              />
            </b-form-group>
          </div>

          <!-- Question Exercise Form -->
          <div v-if="addExerciseType === 'Question'">
            <b-form-group label="Enter the Translated Question *" label-for="enterQuestionTranslation">
              <b-form-input
                id="enterQuestionTranslation"
                placeholder="Enter the translated question"
                v-model="addQuestionTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter Translated Options (separated by a comma ',')" label-for="enterOptionsTranslation">
              <b-form-input
                id="enterOptionsTranslation"
                placeholder="Enter the Translated Options"
                v-model="addQuestionOptionsTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter the translated answer(s) (separated by a comma ',')" label-for="enterAnswerTranslation">
              <b-form-input
                id="enterAnswerTranslation"
                placeholder="Enter the Translated Answer(s)"
                v-model="addQuestionAnswerTranslation"
              />
            </b-form-group>
          </div>
        </div>

        <b-button variant="outline-primary" @click="storeExercise">
          Submit
        </b-button>
      </b-modal>

      <!-- Add Exercise After An Exercise Modal -->
      <b-modal
        v-model="showModalToAddExerciseAfterAnotherExercise"
        hide-footer
        centered
        size="lg"
        title="Add Exercise"
      >
        <span>Select Exercise Type</span>
        <div class="demo-inline-spacing mb-1">
          <b-form-radio
            v-model="addExerciseType"
            name="group-exercise"
            value="Group Exercise"
            class="custom-control-primary"
          >
            Group Exercise
          </b-form-radio>
          <b-form-radio
            v-model="addExerciseType"
            name="information-type"
            value="Information"
            class="custom-control-primary"
          >
            Information
          </b-form-radio>
          <b-form-radio
            v-model="addExerciseType"
            name="conversation-type"
            value="Conversation"
            class="custom-control-primary"
          >
            Conversation
          </b-form-radio>
          <b-form-radio
            v-model="addExerciseType"
            name="match-word-type"
            value="Match Word"
            class="custom-control-primary"
          >
            Match Words
          </b-form-radio>
          <b-form-radio
            v-model="addExerciseType"
            name="question-type"
            value="Question"
            class="custom-control-primary"
          >
            Question
          </b-form-radio>
        </div>
        <!-- Group Exercise Form -->
        <div v-if="addExerciseType === 'Group Exercise'">
          <b-form-group label="Instruction" label-for="enterInstruction">
            <b-form-input
              id="enterInstruction"
              placeholder="Enter the Instructions for this exercise"
              v-model="addGroupExerciseInstruction"
            />
          </b-form-group>
          <b-form-group label="Group Exercise" label-for="groupExercise">
            <quill-editor
              v-model="addGroupExerciseActivity"
              :options="groupExerciseEditorOption"
            />
          </b-form-group>
          <b-row>
            <b-col lg="4">
              <div class="demo-inline-spacing mb-1">
                <span>Requires Student Response?</span>
                <b-form-radio
                  v-model="addGroupExerciseRequiresResponse"
                  name="requires-response-yes"
                  value="Yes"
                  class="custom-control-primary"
                >
                  Yes
                </b-form-radio>
                <b-form-radio
                  v-model="addGroupExerciseRequiresResponse"
                  name="requires-response-no"
                  value="No"
                  class="custom-control-primary"
                >
                  No
                </b-form-radio>
              </div>
            </b-col>
            <b-col lg="8">
              <b-form-group v-if="addGroupExerciseRequiresResponse == 'Yes'" label="Expected Response" label-for="expectedResponse">
                <b-form-input
                  id="enterExpectedResponse"
                  placeholder="Enter the expected input for this exercise"
                  v-model="addGroupExerciseExpectedResponse"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <!-- Information Exercise Form -->
        <div v-if="addExerciseType === 'Information'">
          <b-form-group label="Topic *" label-for="enterTopic">
            <b-form-input
              id="enterTopic"
              placeholder="Enter the Topic for this information"
              v-model="addInformationTopic"
            />
          </b-form-group>
          <b-form-group label="Subtopic" label-for="enterSubtopic">
            <b-form-input
              id="enterSubtopic"
              placeholder="Enter the Subtopic for this information"
              v-model="addInformationSubtopic"
            />
          </b-form-group>

          <b-form-group label="Information *" label-for="information">
            <quill-editor
              v-model="addInformationContent"
              :options="informationEditorOption"
            />
          </b-form-group>
        </div>

        <!-- Conversation Exercise Form -->
        <div v-if="addExerciseType === 'Conversation'">
          <b-form-group label="Conversation *" label-for="conversation">
            <quill-editor
              v-model="addConversation"
              :options="conversationEditorOption"
            />
          </b-form-group>
        </div>

        <!-- Match Word Exercise Form -->
        <div v-if="addExerciseType === 'Match Word'">
          <b-form-group label="Enter Instruction for this exercise *" label-for="enterMatchWordsInstruction">
            <b-form-input
              id="enterMatchWords"
              placeholder="Enter Words to Match"
              v-model="addMatchWordInstruction"
            />
          </b-form-group>
          <b-form-group label="Enter Words to Match (separated by a comma ',') *" label-for="enterMatchWords">
            <b-form-input
              id="enterMatchWords"
              placeholder="Enter Words to Match"
              v-model="addMatchWordWords"
            />
          </b-form-group>
          <b-form-group label="Enter Options (separated by a comma ',')" label-for="enterOptions">
            <b-form-input
              id="enterOptions"
              placeholder="Enter the Options"
              v-model="addMatchWordOptions"
            />
          </b-form-group>
          <b-form-group label="Enter the answer (separated by a comma ',')" label-for="enterAnswer">
            <b-form-input
              id="enterAnswer"
              placeholder="Enter the Answer"
              v-model="addMatchWordAnswer"
            />
          </b-form-group>
        </div>

        <!-- Question Exercise Form -->
        <div v-if="addExerciseType === 'Question'">
          <b-form-group label="Enter the Question *" label-for="enterQuestion">
            <b-form-input
              id="enterQuestion"
              placeholder="Enter the question"
              v-model="addQuestion"
            />
          </b-form-group>
          <b-form-group label="Enter Options (separated by a comma ',')" label-for="enterOptions">
            <b-form-input
              id="enterOptions"
              placeholder="Enter the Options"
              v-model="addQuestionOptions"
            />
          </b-form-group>
          <b-form-group label="Enter the answer (separated by a comma ',')" label-for="enterAnswer">
            <b-form-input
              id="enterAnswer"
              placeholder="Enter the Answer"
              v-model="addQuestionAnswer"
            />
          </b-form-group>
        </div>

        <!-- Add Exercise Image Field -->
        <b-form-group label="Select Exercise's Image" label-for="selectFile">
          <b-form-file
            v-model="addExerciseImage"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg,.jpeg,.png"
          />

          <b-card-text class="my-1">
            Selected image: <strong>{{ addExerciseImage ? addExerciseImage.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <!-- Add Exercise Audio Field -->
        <b-form-group label="Select Exercise's Audio" label-for="selectFile">
          <b-form-file
            v-model="addExerciseAudio"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".mp3,.wav,.m4a"
          />

          <b-card-text class="my-1">
            Selected audio: <strong>{{ addExerciseAudio ? addExerciseAudio.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <!-- Add Exercise Video Field -->
        <b-form-group label="Select Exercise's Video" label-for="selectFile">
          <b-form-file
            v-model="addExerciseVideo"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".mp4"
          />

          <b-card-text class="my-1">
            Selected video: <strong>{{ addExerciseVideo ? addExerciseVideo.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add Translation? </span>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <!-- Exercise Translation -->
        <div v-if="addTranslation === 'yes'">
          <!-- Group Exercise Form -->
          <div v-if="addExerciseType === 'Group Exercise'">
            <b-form-group label="Instruction Translation" label-for="enterInstructionTranslation">
              <b-form-input
                id="enterInstructionTranslation"
                placeholder="Enter the Tranlsated Instructions for this exercise"
                v-model="addGroupExerciseInstructionTranslation"
              />
            </b-form-group>
            <b-form-group label="Group Exercise Translation" label-for="groupExerciseTranslation">
              <quill-editor
                v-model="addGroupExerciseActivityTranslation"
                :options="groupExerciseEditorOption"
              />
            </b-form-group>
            <b-row>
              <b-col lg="8">
                <b-form-group v-if="addGroupExerciseRequiresResponse === 'Yes'" label="Expected Response Translation (optional)" label-for="expectedResponse">
                  <b-form-input
                    id="enterExpectedResponseTranslation"
                    placeholder="Enter the translated expected input for this exercise"
                    v-model="addGroupExerciseExpectedResponseTranslation"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <!-- Information Exercise Form -->
          <div v-if="addExerciseType === 'Information'">
            <b-form-group label="Topic Translation *" label-for="enterTopicTranslation">
              <b-form-input
                id="enterTopicTranslation"
                placeholder="Enter the Tranlation of the Topic for this information"
                v-model="addInformationTopicTranslation"
              />
            </b-form-group>
            <b-form-group label="Subtopic Translation" label-for="enterSubtopicTranslation">
              <b-form-input
                id="enterSubtopicTranslation"
                placeholder="Enter the translated Subtopic for this information"
                v-model="addInformationSubtopicTranslation"
              />
            </b-form-group>

            <b-form-group label="Information Translation *" label-for="informationTranslation">
              <quill-editor
                v-model="addInformationContentTranslation"
                :options="informationEditorOption"
              />
            </b-form-group>
          </div>

          <!-- Conversation Exercise Form -->
          <div v-if="addExerciseType === 'Conversation'">
            <b-form-group label="Conversation Translation *" label-for="conversationTranslation">
              <quill-editor
                v-model="addConversationTranslation"
                :options="conversationEditorOption"
              />
            </b-form-group>
          </div>

          <!-- Match Word Exercise Form -->
          <div v-if="addExerciseType === 'Match Word'">
            <b-form-group label="Enter Translated Instruction for this exercise *" label-for="enterMatchWordsInstructionTranslation">
              <b-form-input
                id="enterMatchWordsTranslation"
                placeholder="Enter Translated Words to Match"
                v-model="addMatchWordInstructionTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter Translated Words to Match (separated by a comma ',') *" label-for="enterMatchWordsTranslated">
              <b-form-input
                id="enterMatchWordsTranslation"
                placeholder="Enter Translated Words to Match"
                v-model="addMatchWordWordsTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter Translated Options (separated by a comma ',')" label-for="enterOptionsTranslation">
              <b-form-input
                id="enterOptionsTranslation"
                placeholder="Enter the Translated Options"
                v-model="addMatchWordOptionsTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter the translated answer(s) (separated by a comma ',')" label-for="enterAnswerTranslation">
              <b-form-input
                id="enterAnswerTranslation"
                placeholder="Enter the Translated Answer(2)"
                v-model="addMatchWordAnswerTranslation"
              />
            </b-form-group>
          </div>

          <!-- Question Exercise Form -->
          <div v-if="addExerciseType === 'Question'">
            <b-form-group label="Enter the Translated Question *" label-for="enterQuestionTranslation">
              <b-form-input
                id="enterQuestionTranslation"
                placeholder="Enter the translated question"
                v-model="addQuestionTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter Translated Options (separated by a comma ',')" label-for="enterOptionsTranslation">
              <b-form-input
                id="enterOptionsTranslation"
                placeholder="Enter the Translated Options"
                v-model="addQuestionOptionsTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter the translated answer(s) (separated by a comma ',')" label-for="enterAnswerTranslation">
              <b-form-input
                id="enterAnswerTranslation"
                placeholder="Enter the Translated Answer(s)"
                v-model="addQuestionAnswerTranslation"
              />
            </b-form-group>
          </div>
        </div>

        <b-button variant="outline-primary" @click="storeExerciseAfterAnotherExercise">
          Submit
        </b-button>
      </b-modal>

      <!-- Edit Exercise Modal -->
      <b-modal
        v-model="editExerciseModal"
        hide-footer
        centered
        size="lg"
        title="Edit Exercise"
      >
        <!-- Group Exercise Form -->
        <div v-if="exerciseData.type === 'GroupExercise'">
          <b-form-group label="Instruction" label-for="enterInstruction">
            <b-form-input
              id="enterInstruction"
              placeholder="Enter the Instructions for this exercise"
              v-model="editGroupExerciseInstruction"
            />
          </b-form-group>
          <b-form-group label="Group Exercise" label-for="groupExercise">
            <quill-editor
              v-model="editGroupExerciseActivity"
              :options="groupExerciseEditorOption"
            />
          </b-form-group>
          <b-row>
            <b-col lg="4">
              <div class="demo-inline-spacing mb-1">
                <span>Requires Student Response?</span>
                <b-form-radio
                  v-model="editGroupExerciseRequiresResponse"
                  name="requires-response-yes"
                  value="Yes"
                  class="custom-control-primary"
                >
                  Yes
                </b-form-radio>
                <b-form-radio
                  v-model="editGroupExerciseRequiresResponse"
                  name="requires-response-no"
                  value="No"
                  class="custom-control-primary"
                >
                  No
                </b-form-radio>
              </div>
            </b-col>
            <b-col lg="8">
              <b-form-group v-if="editGroupExerciseRequiresResponse === 'Yes'" label="Expected Response (optional)" label-for="expectedResponse">
                <b-form-input
                  id="enterExpectedResponse"
                  placeholder="Enter the expected input for this exercise"
                  v-model="editGroupExerciseExpectedResponse"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <!-- Information Exercise Form -->
        <div v-if="exerciseData.type === 'Information'">
          <b-form-group label="Topic *" label-for="enterTopic">
            <b-form-input
              id="enterTopic"
              placeholder="Enter the Topic for this information"
              v-model="editInformationTopic"
            />
          </b-form-group>
          <b-form-group label="Subtopic" label-for="enterSubtopic">
            <b-form-input
              id="enterSubtopic"
              placeholder="Enter the Subtopic for this information"
              v-model="editInformationSubtopic"
            />
          </b-form-group>

          <b-form-group label="Information *" label-for="information">
            <quill-editor
              v-model="editInformationContent"
              :options="informationEditorOption"
            />
          </b-form-group>
        </div>

        <!-- Conversation Exercise Form -->
        <div v-if="exerciseData.type === 'Conversation'">
          <b-form-group label="Conversation *" label-for="conversation">
            <quill-editor
              v-model="editConversation"
              :options="conversationEditorOption"
            />
          </b-form-group>
        </div>

        <!-- Match Word Exercise Form -->
        <div v-if="exerciseData.type === 'MatchWord'">
          <b-form-group label="Enter Instruction for this exercise *" label-for="enterMatchWordsInstruction">
            <b-form-input
              id="enterMatchWords"
              placeholder="Enter Words to Match"
              v-model="editMatchWordInstruction"
            />
          </b-form-group>
          <b-form-group label="Enter Words to Match (separated by a comma ',') *" label-for="enterMatchWords">
            <b-form-input
              id="enterMatchWords"
              placeholder="Enter Words to Match"
              v-model="editMatchWordWords"
            />
          </b-form-group>
          <b-form-group label="Enter Options (separated by a comma ',')" label-for="enterOptions">
            <b-form-input
              id="enterOptions"
              placeholder="Enter the Options"
              v-model="editMatchWordOptions"
            />
          </b-form-group>
          <b-form-group label="Enter the answer (separated by a comma ',')" label-for="enterAnswer">
            <b-form-input
              id="enterAnswer"
              placeholder="Enter the Answer"
              v-model="editMatchWordAnswer"
            />
          </b-form-group>
        </div>

        <!-- Question Exercise Form -->
        <div v-if="exerciseData.type === 'Question'">
          <b-form-group label="Enter the Question *" label-for="enterQuestion">
            <b-form-input
              id="enterQuestion"
              placeholder="Enter the question"
              v-model="editQuestion"
            />
          </b-form-group>
          <b-form-group label="Enter Options (separated by a comma ',')" label-for="enterOptions">
            <b-form-input
              id="enterOptions"
              placeholder="Enter the Options"
              v-model="editQuestionOptions"
            />
          </b-form-group>
          <b-form-group label="Enter the answer (separated by a comma ',')" label-for="enterAnswer">
            <b-form-input
              id="enterAnswer"
              placeholder="Enter the Answer"
              v-model="editQuestionAnswer"
            />
          </b-form-group>
        </div>

        <!-- Edit Exercise Image Field -->
        <b-form-group label="Select Exercise's Image" label-for="selectFile">
          <b-form-file
            v-model="editExerciseImage"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg,.jpeg,.png"
          />

          <b-card-text class="my-1">
            Selected image: <strong>{{ editExerciseImage ? editExerciseImage.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <!-- Edit Exercise Audio Field -->
        <b-form-group label="Select Exercise's Audio" label-for="selectFile">
          <b-form-file
            v-model="editExerciseAudio"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".mp3,.wav,.m4a"
          />

          <b-card-text class="my-1">
            Selected audio: <strong>{{ editExerciseAudio ? editExerciseAudio.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <!-- Edit Exercise Video Field -->
        <b-form-group label="Select Exercise's Video" label-for="selectFile">
          <b-form-file
            v-model="editExerciseVideo"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".mp4"
          />

          <b-card-text class="my-1">
            Selected video: <strong>{{ editExerciseVideo ? editExerciseVideo.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add Translation? </span>
          <b-form-radio
            v-model="editTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="editTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <!-- Edit Exercise Translation -->
        <div v-if="editTranslation === 'yes'">
          <!-- Group Exercise Form -->
          <div v-if="exerciseData.type === 'GroupExercise'">
            <b-form-group label="Instruction Translation" label-for="enterInstructionTranslation">
              <b-form-input
                id="enterInstructionTranslation"
                placeholder="Enter the Tranlsated Instructions for this exercise"
                v-model="editGroupExerciseInstructionTranslation"
              />
            </b-form-group>
            <b-form-group label="Group Exercise Translation" label-for="groupExerciseTranslation">
              <quill-editor
                v-model="editGroupExerciseActivityTranslation"
                :options="groupExerciseEditorOption"
              />
            </b-form-group>
            <b-row>
              <b-col lg="8">
                <b-form-group v-if="editGroupExerciseRequiresResponse === 'Yes'" label="Expected Response Translation (optional)" label-for="expectedResponse">
                  <b-form-input
                    id="enterExpectedResponseTranslation"
                    placeholder="Enter the translated expected input for this exercise"
                    v-model="editGroupExerciseExpectedResponseTranslation"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <!-- Information Exercise Form -->
          <div v-if="exerciseData.type === 'Information'">
            <b-form-group label="Topic Translation *" label-for="enterTopicTranslation">
              <b-form-input
                id="enterTopicTranslation"
                placeholder="Enter the Tranlation of the Topic for this information"
                v-model="editInformationTopicTranslation"
              />
            </b-form-group>
            <b-form-group label="Subtopic Translation" label-for="enterSubtopicTranslation">
              <b-form-input
                id="enterSubtopicTranslation"
                placeholder="Enter the translated Subtopic for this information"
                v-model="editInformationSubtopicTranslation"
              />
            </b-form-group>

            <b-form-group label="Information Translation *" label-for="informationTranslation">
              <quill-editor
                v-model="editInformationContentTranslation"
                :options="informationEditorOption"
              />
            </b-form-group>
          </div>

          <!-- Conversation Exercise Form -->
          <div v-if="exerciseData.type === 'Conversation'">
            <b-form-group label="Conversation Translation *" label-for="conversationTranslation">
              <quill-editor
                v-model="editConversationTranslation"
                :options="conversationEditorOption"
              />
            </b-form-group>
          </div>

          <!-- Match Word Exercise Form -->
          <div v-if="exerciseData.type === 'MatchWord'">
            <b-form-group label="Enter Translated Instruction for this exercise *" label-for="enterMatchWordsInstructionTranslation">
              <b-form-input
                id="enterMatchWordsTranslation"
                placeholder="Enter Translated Words to Match"
                v-model="editMatchWordInstructionTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter Translated Words to Match (separated by a comma ',') *" label-for="enterMatchWordsTranslated">
              <b-form-input
                id="enterMatchWordsTranslation"
                placeholder="Enter Translated Words to Match"
                v-model="editMatchWordWordsTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter Translated Options (separated by a comma ',')" label-for="enterOptionsTranslation">
              <b-form-input
                id="enterOptionsTranslation"
                placeholder="Enter the Translated Options"
                v-model="editMatchWordOptionsTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter the translated answer(s) (separated by a comma ',')" label-for="enterAnswerTranslation">
              <b-form-input
                id="enterAnswerTranslation"
                placeholder="Enter the Translated Answer(2)"
                v-model="editMatchWordAnswerTranslation"
              />
            </b-form-group>
          </div>

          <!-- Question Exercise Form -->
          <div v-if="exerciseData.type === 'Question'">
            <b-form-group label="Enter the Translated Question *" label-for="enterQuestionTranslation">
              <b-form-input
                id="enterQuestionTranslation"
                placeholder="Enter the translated question"
                v-model="editQuestionTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter Translated Options (separated by a comma ',')" label-for="enterOptionsTranslation">
              <b-form-input
                id="enterOptionsTranslation"
                placeholder="Enter the Translated Options"
                v-model="editQuestionOptionsTranslation"
              />
            </b-form-group>
            <b-form-group label="Enter the translated answer(s) (separated by a comma ',')" label-for="enterAnswerTranslation">
              <b-form-input
                id="enterAnswerTranslation"
                placeholder="Enter the Translated Answer(s)"
                v-model="editQuestionAnswerTranslation"
              />
            </b-form-group>
          </div>
        </div>

        <b-button variant="outline-primary" @click="updateExercise">
          Submit
        </b-button>
      </b-modal>

      <!-- Add Exercise Guide -->
      <b-modal
        v-model="addExerciseGuideModal"
        hide-footer
        centered
        size="lg"
        title="Add Exercise Guide"
      >
        <b-form-group label="Exercise Guide" label-for="exerciseGuide">
          <quill-editor
            v-model="addExerciseGuide"
            :options="editorOption"
          />
        </b-form-group>
        <div class="demo-inline-spacing mb-1">
          <span>Add Translation? </span>
          <b-form-radio
            v-model="addGuideTranslation"
            name="add-guide-yes"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="addGuideTranslation"
            name="add-guide-no"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <div v-if="addGuideTranslation === 'yes'">
          <b-form-group label="Exercise Guide" label-for="exerciseGuide">
            <quill-editor
              v-model="addExerciseGuideTranslation"
              :options="editorOption"
            />
          </b-form-group>
        </div>

        <b-button variant="outline-primary" @click="storeExerciseGuide()">
          Submit
        </b-button>
      </b-modal>

      <!-- Edit Exercise Guide Modal -->
      <b-modal
        v-model="editExerciseGuideModal"
        hide-footer
        centered
        size="lg"
        title="View/Edit Exercise Guide"
      >
        <div class="demo-inline-spacing mb-1">
          <span>View Or Edit Guide? </span>
          <b-form-radio
            v-model="guideViewStatus"
            name="view-lesson"
            value="view"
            class="custom-control-primary"
          >
            View
          </b-form-radio>
          <b-form-radio
            v-model="guideViewStatus"
            name="edit-lesson"
            value="edit"
            class="custom-control-primary"
          >
            Edit
          </b-form-radio>
        </div>

        <div v-if="guideViewStatus === 'view'">
          <b-row>
            <b-col :cols="guideHasTranslation ? '6' : '12'">
              <label for="">Guide</label>
              <b-card v-html="formatHtml(editExerciseGuide)">
              </b-card>
            </b-col>
            <b-col cols="6" v-if="guideHasTranslation">
              <label for="">Guide Translation</label>
              <b-card v-html="formatHtml(editExerciseGuideTranslation)">
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div v-if="guideViewStatus === 'edit'">
          <b-form-group label="Exercise Guide Translation" label-for="exerciseGuideTranslation">
            <quill-editor
              v-model="editExerciseGuide"
              :options="editorOption"
            />
          </b-form-group>
          <div class="demo-inline-spacing mb-1">
            <span>Add/Edit Translation? </span>
            <b-form-radio
              v-model="editGuideTranslation"
              name="edit-guide-yes"
              value="yes"
              class="custom-control-primary"
            >
              Yes
            </b-form-radio>
            <b-form-radio
              v-model="editGuideTranslation"
              name="edit-guide-no"
              value="no"
              class="custom-control-primary"
            >
              No
            </b-form-radio>
          </div>

          <div v-if="editGuideTranslation === 'yes'">
            <b-form-group label="Exercise Guide Translation" label-for="exerciseGuideTranslation">
              <quill-editor
                v-model="editExerciseGuideTranslation"
                :options="editorOption"
              />
            </b-form-group>
          </div>

          <b-button variant="outline-primary" @click="updateExerciseGuide()">
            Submit
          </b-button>
        </div>
      </b-modal>

      <b-table
        ref="refExercisesListTable"
        class="position-relative"
        :items="fetchExercises"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Type -->
        <template #cell(type)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ formatType(data.item.exerciseable_type) }}</span>
          </div>
        </template>

        <!-- Column: Audio -->
        <template #cell(Audio-Uploaded)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.exerciseable.audio == null ? 'No' : 'Yes' }}</span>
          </div>
        </template>

        <!-- Column: Video -->
        <template #cell(Video-Uploaded)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.exerciseable.video == null ? 'No' : 'Yes' }}</span>
          </div>
        </template>

        <!-- Column: Image -->
        <template #cell(Image-Uploaded)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.exerciseable.image == null ? 'No' : 'Yes' }}</span>
          </div>
        </template>

        <!-- Column: Translation -->
        <template #cell(Translation-Uploaded)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.exerciseable.translation == null ? 'No' : 'Yes' }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-info"
              @click="getExerciseDetails('Show', data.item)"
            >
              View
            </b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              @click="getExerciseDetails('Edit', data.item)"
            >
              Edit
            </b-button>
            <b-dropdown
              id="dropdown-1"
              text="More"
              size="sm"
              variant="primary"
            >
              <b-dropdown-item>
                <b-button
                  class="mr-1"
                  size="sm"
                  variant="outline-primary"
                  @click="addExerciseAfterAnotherExerciseModalShow('Before', data.item)"
                >
                  Add Exercise Before
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button
                  class="mr-1"
                  size="sm"
                  variant="outline-info"
                  @click="addExerciseAfterAnotherExerciseModalShow('After', data.item)"
                >
                  Add Exercise After
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button
                  v-if="data.item.exerciseable.guide == null"
                  variant="outline-warning"
                  size="sm"
                  @click="showAddExerciseGuideModal(data.item)"
                >
                  Add Guide
                </b-button>
                <b-button
                  v-if="data.item.exerciseable.guide != null"
                  variant="outline-warning"
                  size="sm"
                  @click="getExerciseGuideDetails(data.item)"
                >
                  View Guide
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="deleteExercise(data.item.id)"
                >
                  Delete Exercise
                </b-button>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalExercises"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ExerciseMedia from './ExerciseMedia.vue'

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect,
  BModal,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BCardText,
  BFormSpinbutton,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted, onBeforeMount } from "@vue/composition-api";
import useExercisesList from "./useExercisesList";
import contentStore from "../contentStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect,
    BModal,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BCardText,
    BFormSpinbutton,
    BFormRadio,

    quillEditor,
    vSelect,

    ExerciseMedia,
  },
  setup() {
    const CONTENT_APP_STORE_MODULE_NAME = "app-content";

    const maxChar = ref(400);

    // Use toast
    const toast = useToast();

    const guideViewStatus = ref('view')
    const guideHasTranslation = ref(false)

    const editorOption = {
      theme: 'bubble',
    }

    const conversationEditorOption = {
      theme: 'bubble',
    }

    const informationEditorOption = {
      theme: 'bubble',
    }

    const groupExerciseEditorOption = {
      theme: 'bubble',
    }

    const showGroupExerciseModal = ref(false)
    const showConversationModal = ref(false)
    const showInformationModal = ref(false)
    const showMatchWordModal = ref(false)
    const showQuestionModal = ref(false)

    // Group Exercise Variables
    const groupExerciseInstruction = ref("")
    const groupExerciseActivity = ref("")
    const groupExerciseRequiresResponse = ref("No")
    const groupExerciseExpectedResponse = ref("")

    const groupExerciseInstructionTranslation = ref("")
    const groupExerciseActivityTranslation = ref("")
    const groupExerciseExpectedResponseTranslation = ref("")

    // Information variables
    const informationTopicExercise = ref("")
    const informationSubtopicExercise = ref("")
    const informationContent = ref("")

    const informationTopicExerciseTranslation = ref("")
    const informationSubtopicExerciseTranslation = ref("")
    const informationContentTranslation = ref("")

    // Conversation variables
    const conversationConversation = ref("")

    const conversationConversationTranslation = ref("")

    // Match Word Variables
    const matchWordInstruction = ref("")
    const matchWordWords = ref([])
    const matchWordOptions = ref([])
    const matchWordAnswer = ref([])

    const matchWordInstructionTranslation = ref("")
    const matchWordWordsTranslation = ref([])
    const matchWordOptionsTranslation = ref([])
    const matchWordAnswerTranslation = ref([])

    // Question variables
    const questionQuestion = ref("")
    const questionOptions = ref([])
    const questionAnswer = ref([])

    const questionQuestionTranslation = ref("")
    const questionOptionsTranslation = ref([])
    const questionAnswerTranslation = ref([])

    // Register module
    if (!store.hasModule(CONTENT_APP_STORE_MODULE_NAME))
      store.registerModule(CONTENT_APP_STORE_MODULE_NAME, contentStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CONTENT_APP_STORE_MODULE_NAME);
    });

    onBeforeMount(() => {
      exerciseData.value = {
        type: "",
      }
    })

    const hasTranslation = ref(false)

    const formatType = (type) => {
      return type.split('\\')[2];
    }

    const formatHtml = (string) => {
      return string.replace(/(?:\\r\\n|\\r|\\n )/g, '<br/>')
    }

    const getExerciseDetails = (action, item) => {
      if(action == 'Show') {
        showExerciseDetails(item)
      } else if(action == 'Edit') {
        editExerciseDetails(item)
      }
    }

    const editExerciseDetails = (item) => {
      exerciseData.value = {
        id: item.exerciseable_id,
        type: formatType(item.exerciseable_type)
      }
      switch (item.exerciseable_type) {
        case 'App\\Models\\GroupExercise':
          editGroupExerciseInstruction.value = item.exerciseable.instruction
          editGroupExerciseActivity.value = formatHtml(item.exerciseable.activity)
          editGroupExerciseRequiresResponse.value = item.exerciseable.requires_response == true ? 'Yes' : 'No'
          editGroupExerciseExpectedResponse.value = item.exerciseable.expected_response
          if (item.exerciseable.translation) {
            editGroupExerciseInstructionTranslation.value = item.exerciseable.translation.instruction
            editGroupExerciseActivityTranslation.value = formatHtml(item.exerciseable.translation.activity)
            editGroupExerciseExpectedResponseTranslation.value = item.exerciseable.translation.expected_response
            editTranslation.value = "yes"
          }
          editExerciseModal.value = true
          break;
        case 'App\\Models\\Information':
          editInformationTopic.value = item.exerciseable.topic
          editInformationSubtopic.value = item.exerciseable.subtopic
          editInformationContent.value = formatHtml(item.exerciseable.content)
          if(item.exerciseable.translation) {
            editInformationTopicTranslation.value = item.exerciseable.translation.topic
            editInformationSubtopicTranslation.value = item.exerciseable.translation.subtopic
            editInformationContentTranslation.value = formatHtml(item.exerciseable.translation.content)
            editTranslation.value = "yes"
          }
          editExerciseModal.value = true
          break;
        case 'App\\Models\\Conversation':
          editConversation.value = formatHtml(item.exerciseable.conversation)
          if(item.exerciseable.translation) {
            editConversationTranslation.value = formatHtml(item.exerciseable.translation.conversation)
            editTranslation.value = "yes"
          }
          editExerciseModal.value = true
          break;
        case 'App\\Models\\MatchWord':
          editMatchWordInstruction.value = item.exerciseable.instruction
          editMatchWordWords.value = item.exerciseable.words.join(', ')
          editMatchWordOptions.value = item.exerciseable.options.join(', ')
          editMatchWordAnswer.value = item.exerciseable.answer.join(', ')
          if (item.exerciseable.translation) {
            editMatchWordInstructionTranslation.value = item.exerciseable.translation.instruction
            editMatchWordWordsTranslation.value = item.exerciseable.translation.words.join(', ')
            editMatchWordOptionsTranslation.value = item.exerciseable.translation.options.join(', ')
            editMatchWordAnswerTranslation.value = item.exerciseable.translation.answer.join(', ')
            editTranslation.value = "yes"
          }
          editExerciseModal.value = true
        case 'App\\Models\\Question':
          editQuestion.value = item.exerciseable.question
          editQuestionOptions.value = item.exerciseable.options.join(', ')
          editQuestionAnswer.value = item.exerciseable.answer.join(', ')
          if (item.exerciseable.translation) {
            editQuestionTranslation.value = item.exerciseable.translation.question
            editQuestionOptionsTranslation.value = item.exerciseable.translation.options.join(', ')
            editQuestionAnswerTranslation.value = item.exerciseable.translation.answer.join(', ')
            editTranslation.value = "yes"
          }
          editExerciseModal.value = true
          break;
        default:
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching exercise",
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          })
          break;
      }
    }

    const addExerciseAfterAnotherExerciseModalShow = (placement, item) => {
      prevExerciseId.value = item.id
      exercisePlacement.value = placement
      showModalToAddExerciseAfterAnotherExercise.value = true
    }

    const showExerciseDetails = (item) => {
      exerciseData.value = item
      hasTranslation.value = false
      switch (exerciseData.value.exerciseable_type) {
        case 'App\\Models\\GroupExercise':
          groupExerciseInstruction.value = exerciseData.value.exerciseable.instruction
          groupExerciseActivity.value = exerciseData.value.exerciseable.activity
          groupExerciseRequiresResponse.value = exerciseData.value.exerciseable.requires_response == true ? 'Yes' : 'No'
          groupExerciseExpectedResponse.value = exerciseData.value.exerciseable.expected_response
          if (exerciseData.value.exerciseable.translation) {
            groupExerciseInstructionTranslation.value = exerciseData.value.exerciseable.translation.instruction
            groupExerciseActivityTranslation.value = exerciseData.value.exerciseable.translation.activity
            groupExerciseExpectedResponseTranslation.value = exerciseData.value.exerciseable.translation.expected_response
            hasTranslation.value = true
          }
          showGroupExerciseModal.value = true
          break;
        case 'App\\Models\\Information':
          informationTopicExercise.value = exerciseData.value.exerciseable.topic
          informationSubtopicExercise.value = exerciseData.value.exerciseable.subtopic
          informationContent.value = exerciseData.value.exerciseable.content
          if(exerciseData.value.exerciseable.translation) {
            informationTopicExerciseTranslation.value = exerciseData.value.exerciseable.translation.topic
            informationSubtopicExerciseTranslation.value = exerciseData.value.exerciseable.translation.subtopic
            informationContentTranslation.value = exerciseData.value.exerciseable.translation.content
            hasTranslation.value = true
          }
          showInformationModal.value = true
          break;
        case 'App\\Models\\Conversation':
          conversationConversation.value = exerciseData.value.exerciseable.conversation
          if(exerciseData.value.exerciseable.translation) {
            conversationConversationTranslation.value = exerciseData.value.exerciseable.translation.conversation
            hasTranslation.value = true
          }
          showConversationModal.value = true
          break;
        case 'App\\Models\\MatchWord':
          matchWordInstruction.value = exerciseData.value.exerciseable.instruction
          matchWordWords.value = exerciseData.value.exerciseable.words
          matchWordOptions.value = exerciseData.value.exerciseable.options
          matchWordAnswer.value = exerciseData.value.exerciseable.answer
          if(exerciseData.value.exerciseable.translation) {
            matchWordInstructionTranslation.value = exerciseData.value.exerciseable.translation.instruction
            matchWordWordsTranslation.value = exerciseData.value.exerciseable.translation.words
            matchWordOptionsTranslation.value = exerciseData.value.exerciseable.translation.options
            matchWordAnswerTranslation.value = exerciseData.value.exerciseable.translation.answer
            hasTranslation.value = true
          }
          showMatchWordModal.value = true
          break;
        case 'App\\Models\\Question':
          questionQuestion.value = exerciseData.value.exerciseable.question
          questionOptions.value = exerciseData.value.exerciseable.options
          questionAnswer.value = exerciseData.value.exerciseable.answer
          hasTranslation.value = false
          if(exerciseData.value.exerciseable.translation) {
            questionQuestionTranslation.value = exerciseData.value.exerciseable.translation.question
            questionOptionsTranslation.value = exerciseData.value.exerciseable.translation.options
            questionAnswerTranslation.value = exerciseData.value.exerciseable.translation.answer
            hasTranslation.value = true
          }
          showQuestionModal.value = true
          break;
        default:
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching exercise",
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          })
          break;
      }
    }

    const showAddExerciseGuideModal = (item) => {
      addExerciseIdGuide.value = item.id
      addExerciseGuideModal.value = true
    }

    const getExerciseGuideDetails = (item) => {
      editExerciseGuideId.value = item.exerciseable.guide.id
      editExerciseGuide.value = item.exerciseable.guide.guide
      editExerciseGuideTranslation.value = ""
      guideHasTranslation.value = false
      if(item.exerciseable.guide.translation) {
        editExerciseGuideTranslation.value = item.exerciseable.guide.translation.guide
        guideHasTranslation.value = true
      }
      guideViewStatus.value = "view"
      editExerciseGuideModal.value = true
    }

    const isAddNewExerciseSidebarActive = ref(false);

    const {
      fetchExercises,
      storeExercise,
      updateExercise,
      tableColumns,
      per_page,
      current_page,
      totalExercises,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refExercisesListTable,
      refetchData,

      exerciseData,

      // Add Exercise
      addExerciseModal,
      addExerciseType,
      addTranslation,

      editExerciseModal,
      editTranslation,

      // Group Exercise
      addGroupExerciseInstruction,
      addGroupExerciseActivity,
      addGroupExerciseRequiresResponse,
      addGroupExerciseExpectedResponse,

      addGroupExerciseInstructionTranslation,
      addGroupExerciseActivityTranslation,
      addGroupExerciseExpectedResponseTranslation,

      editGroupExerciseInstruction,
      editGroupExerciseActivity,
      editGroupExerciseRequiresResponse,
      editGroupExerciseExpectedResponse,

      editGroupExerciseInstructionTranslation,
      editGroupExerciseActivityTranslation,
      editGroupExerciseExpectedResponseTranslation,

      // Information
      addInformationTopic,
      addInformationSubtopic,
      addInformationContent,

      addInformationTopicTranslation,
      addInformationSubtopicTranslation,
      addInformationContentTranslation,

      editInformationTopic,
      editInformationSubtopic,
      editInformationContent,

      editInformationTopicTranslation,
      editInformationSubtopicTranslation,
      editInformationContentTranslation,

      // Conversation
      addConversation,

      addConversationTranslation,

      editConversation,

      editConversationTranslation,

      // Match Word
      addMatchWordInstruction,
      addMatchWordWords,
      addMatchWordOptions,
      addMatchWordAnswer,

      addMatchWordInstructionTranslation,
      addMatchWordWordsTranslation,
      addMatchWordOptionsTranslation,
      addMatchWordAnswerTranslation,

      editMatchWordInstruction,
      editMatchWordWords,
      editMatchWordOptions,
      editMatchWordAnswer,

      editMatchWordInstructionTranslation,
      editMatchWordWordsTranslation,
      editMatchWordOptionsTranslation,
      editMatchWordAnswerTranslation,

      // Question
      addQuestion,
      addQuestionOptions,
      addQuestionAnswer,

      addQuestionTranslation,
      addQuestionOptionsTranslation,
      addQuestionAnswerTranslation,

      editQuestion,
      editQuestionOptions,
      editQuestionAnswer,

      editQuestionTranslation,
      editQuestionOptionsTranslation,
      editQuestionAnswerTranslation,

      // Audio
      addExerciseAudio,
      editExerciseAudio,

      // Video
      addExerciseVideo,
      editExerciseVideo,

      // Image
      addExerciseImage,
      editExerciseImage,

      // Add Exercise Guide
      addExerciseGuideModal,
      addExerciseIdGuide,
      addExerciseGuide,
      addGuideTranslation,
      addExerciseGuideTranslation,

      // Edit Exercise Guide
      editExerciseGuideModal,
      editExerciseGuideId,
      editExerciseGuide,
      editGuideTranslation,
      editExerciseGuideTranslation,

      storeExerciseGuide,
      updateExerciseGuide,

      showModalToAddExerciseAfterAnotherExercise,
      exercisePlacement,
      prevExerciseId,
      storeExerciseAfterAnotherExercise,

      deleteExercise,
      deleteExerciseId,

    } = useExercisesList();

    return {
      // Sidebar
      isAddNewExerciseSidebarActive,

      maxChar,
      editorOption,

      fetchExercises,
      storeExercise,
      updateExercise,
      tableColumns,
      per_page,
      current_page,
      totalExercises,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refExercisesListTable,
      refetchData,

      formatType,
      formatHtml,

      hasTranslation,
      exerciseData,

      // Add Exercise
      addExerciseModal,
      addExerciseType,
      addTranslation,

      editExerciseModal,
      editTranslation,

      // Group Exercise
      addGroupExerciseInstruction,
      addGroupExerciseActivity,
      addGroupExerciseRequiresResponse,
      addGroupExerciseExpectedResponse,

      addGroupExerciseInstructionTranslation,
      addGroupExerciseActivityTranslation,
      addGroupExerciseExpectedResponseTranslation,

      editGroupExerciseInstruction,
      editGroupExerciseActivity,
      editGroupExerciseRequiresResponse,
      editGroupExerciseExpectedResponse,

      editGroupExerciseInstructionTranslation,
      editGroupExerciseActivityTranslation,
      editGroupExerciseExpectedResponseTranslation,

      // Information
      addInformationTopic,
      addInformationSubtopic,
      addInformationContent,

      addInformationTopicTranslation,
      addInformationSubtopicTranslation,
      addInformationContentTranslation,

      editInformationTopic,
      editInformationSubtopic,
      editInformationContent,

      editInformationTopicTranslation,
      editInformationSubtopicTranslation,
      editInformationContentTranslation,

      // Conversation
      addConversation,

      addConversationTranslation,

      editConversation,

      editConversationTranslation,

      // Match Word
      addMatchWordInstruction,
      addMatchWordWords,
      addMatchWordOptions,
      addMatchWordAnswer,

      addMatchWordInstructionTranslation,
      addMatchWordWordsTranslation,
      addMatchWordOptionsTranslation,
      addMatchWordAnswerTranslation,

      editMatchWordInstruction,
      editMatchWordWords,
      editMatchWordOptions,
      editMatchWordAnswer,

      editMatchWordInstructionTranslation,
      editMatchWordWordsTranslation,
      editMatchWordOptionsTranslation,
      editMatchWordAnswerTranslation,

      // Question
      addQuestion,
      addQuestionOptions,
      addQuestionAnswer,

      addQuestionTranslation,
      addQuestionOptionsTranslation,
      addQuestionAnswerTranslation,

      editQuestion,
      editQuestionOptions,
      editQuestionAnswer,

      editQuestionTranslation,
      editQuestionOptionsTranslation,
      editQuestionAnswerTranslation,

      // Audio
      addExerciseAudio,
      editExerciseAudio,

      // Video
      addExerciseVideo,
      editExerciseVideo,

      // Image
      addExerciseImage,
      editExerciseImage,

      getExerciseDetails,

      // Show Exercises Modals
      showGroupExerciseModal,
      showConversationModal,
      showInformationModal,
      showMatchWordModal,
      showQuestionModal,

      // Show Group Exercise Variables
      groupExerciseInstruction,
      groupExerciseActivity,
      groupExerciseRequiresResponse,
      groupExerciseExpectedResponse,

      groupExerciseInstructionTranslation,
      groupExerciseActivityTranslation,
      groupExerciseExpectedResponseTranslation,

      // Show Information Exercise Variables
      informationTopicExercise,
      informationSubtopicExercise,
      informationContent,

      informationTopicExerciseTranslation,
      informationSubtopicExerciseTranslation,
      informationContentTranslation,

      // Show Conversation Exercise variables
      conversationConversation,

      conversationConversationTranslation,

      // Show Match Words exercise variables
      matchWordInstruction,
      matchWordWords,
      matchWordOptions,
      matchWordAnswer,

      matchWordInstructionTranslation,
      matchWordWordsTranslation,
      matchWordOptionsTranslation,
      matchWordAnswerTranslation,

      // Show Question exercise variables
      questionQuestion,
      questionOptions,
      questionAnswer,

      questionQuestionTranslation,
      questionOptionsTranslation,
      questionAnswerTranslation,

      conversationEditorOption,
      informationEditorOption,
      groupExerciseEditorOption,

      // Add Exercise Guide
      addExerciseGuideModal,
      addExerciseIdGuide,
      addExerciseGuide,
      addGuideTranslation,
      addExerciseGuideTranslation,

      // Edit Exercise Guide
      editExerciseGuideModal,
      editExerciseGuideId,
      editExerciseGuide,
      editGuideTranslation,
      editExerciseGuideTranslation,

      showAddExerciseGuideModal,
      getExerciseGuideDetails,
      storeExerciseGuide,
      updateExerciseGuide,

      addExerciseAfterAnotherExerciseModalShow,

      showModalToAddExerciseAfterAnotherExercise,
      exercisePlacement,
      prevExerciseId,
      storeExerciseAfterAnotherExercise,

      guideViewStatus,
      guideHasTranslation,

      deleteExercise,
      deleteExerciseId,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style scoped>
.pre-formatted {
  white-space: pre-line;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
