<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-card title="Image">
          <div v-if="exercise.exerciseable.image">
            <b-img-lazy
              :src="exercise.exerciseable.image.image"
              fluid
              class="w-100"
            />
          </div>
          <b-card v-else>
            <b-card-text class="d-flex justify-content-center"
              >No Image Uploaded</b-card-text
            >
          </b-card>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Audio">
          <div v-if="exercise.exerciseable.audio">
            <b-card>
              <audio-player
                ref="audioPlayer"
                :audio-list="[exercise.exerciseable.audio.audio]"
                theme-color="#7D71F5"
                :show-next-button="false"
                :show-prev-button="false"
              />
            </b-card>
          </div>
          <b-card v-else>
            <b-card-text class="d-flex justify-content-center"
              >No Audio Uploaded</b-card-text
            >
          </b-card>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Video">
      <div v-if="exercise.exerciseable.video">
        <b-embed
          type="video"
          aspect="16by9"
          :src="exercise.exerciseable.video.video"
          allowfullscreen
        />
      </div>
      <b-card v-else>
        <b-card-text class="d-flex justify-content-center"
          >No Video Uploaded</b-card-text
        >
      </b-card>
    </b-card>
  </div>
</template>

<script>
import AudioPlayer from "@liripeng/vue-audio-player";
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BTabs,
  BTab,
  BFormRadio,
  BBadge,
  BEmbed,
  BImgLazy,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BTabs, 
    BTab,
    BFormRadio,
    BBadge,
    BEmbed,
    BImgLazy,
    AudioPlayer
  },
  props: {
    exercise: {
      type: Object
    }
  }
};
</script>
