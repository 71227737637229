import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useExercisesList() {
  // Use toast
  const toast = useToast()

  const refExercisesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'type' },
    { key: 'Audio-Uploaded' },
    { key: 'Video-Uploaded' },
    { key: 'Image-Uploaded' },
    { key: 'Translation-Uploaded' },
    { key: 'actions' },
  ]
  const per_page = ref(10)
  const totalExercises = ref(0)
  const current_page = ref(1)
  const per_pageOptions = [10, 20, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  // Add exercise variables
  const addExerciseModal = ref(false)
  const addExerciseLessonId = ref('')
  const addExerciseType = ref('Group Exercise')
  const addTranslation = ref('no')

  // Edit Exercise Variables
  const editExerciseModal = ref(false)
  const editTranslation = ref('no')

  // Group Exercise
  const addGroupExerciseInstruction = ref('')
  const addGroupExerciseActivity = ref('')
  const addGroupExerciseRequiresResponse = ref('No')
  const addGroupExerciseExpectedResponse = ref('')

  const addGroupExerciseInstructionTranslation = ref('')
  const addGroupExerciseActivityTranslation = ref('')
  const addGroupExerciseExpectedResponseTranslation = ref('')

  // Edit Group Exercise
  const editGroupExerciseInstruction = ref('')
  const editGroupExerciseActivity = ref('')
  const editGroupExerciseRequiresResponse = ref('No')
  const editGroupExerciseExpectedResponse = ref('')

  const editGroupExerciseInstructionTranslation = ref('')
  const editGroupExerciseActivityTranslation = ref('')
  const editGroupExerciseExpectedResponseTranslation = ref('')

  // Information
  const addInformationTopic = ref('')
  const addInformationSubtopic = ref('')
  const addInformationContent = ref('')

  const addInformationTopicTranslation = ref('')
  const addInformationSubtopicTranslation = ref('')
  const addInformationContentTranslation = ref('')

  // Edit Information
  const editInformationTopic = ref('')
  const editInformationSubtopic = ref('')
  const editInformationContent = ref('')

  const editInformationTopicTranslation = ref('')
  const editInformationSubtopicTranslation = ref('')
  const editInformationContentTranslation = ref('')

  // Conversation
  const addConversation = ref('')

  const addConversationTranslation = ref('')

  // Edit Conversation
  const editConversation = ref('')

  const editConversationTranslation = ref('')

  // Match Word
  const addMatchWordInstruction = ref('')
  const addMatchWordWords = ref('')
  const addMatchWordOptions = ref('')
  const addMatchWordAnswer = ref('')

  const addMatchWordInstructionTranslation = ref('')
  const addMatchWordWordsTranslation = ref('')
  const addMatchWordOptionsTranslation = ref('')
  const addMatchWordAnswerTranslation = ref('')

  // Edit Match Word
  const editMatchWordInstruction = ref('')
  const editMatchWordWords = ref('')
  const editMatchWordOptions = ref('')
  const editMatchWordAnswer = ref('')

  const editMatchWordInstructionTranslation = ref('')
  const editMatchWordWordsTranslation = ref('')
  const editMatchWordOptionsTranslation = ref('')
  const editMatchWordAnswerTranslation = ref('')

  // Question
  const addQuestion = ref('')
  const addQuestionOptions = ref('')
  const addQuestionAnswer = ref('')

  const addQuestionTranslation = ref('')
  const addQuestionOptionsTranslation = ref('')
  const addQuestionAnswerTranslation = ref('')

  // Edit Question
  const editQuestion = ref('')
  const editQuestionOptions = ref('')
  const editQuestionAnswer = ref('')

  const editQuestionTranslation = ref('')
  const editQuestionOptionsTranslation = ref('')
  const editQuestionAnswerTranslation = ref('')

  // Audio
  const addExerciseAudio = ref(null)
  // Edit Audio
  const editExerciseAudio = ref(null)

  // Video
  const addExerciseVideo = ref(null)
  // Edit Video
  const editExerciseVideo = ref(null)

  // Image
  const addExerciseImage = ref(null)
  // Edit Image
  const editExerciseImage = ref(null)

  // Single exerise Data
  const exerciseData = ref(null)

  // Add Exercise Guide
  const addExerciseGuideModal = ref(false)
  const addExerciseIdGuide = ref('')
  const addExerciseGuide = ref('')
  const addGuideTranslation = ref('no')
  const addExerciseGuideTranslation = ref('')

  // Edit Exercise Guide
  const editExerciseGuideModal = ref(false)
  const editExerciseGuideId = ref('')
  const editExerciseGuide = ref('')
  const editGuideTranslation = ref('no')
  const editExerciseGuideTranslation = ref('')

  // Add Exercise After Another Exercise Variable
  const prevExerciseId = ref('')
  const exercisePlacement = ref('')
  const showModalToAddExerciseAfterAnotherExercise = ref(false)

  // Delete exericse varable
  const deleteExerciseId = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refExercisesListTable.value
      ? refExercisesListTable.value.localItems.length
      : 0
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalExercises.value,
    }
  })

  const refetchData = () => {
    refExercisesListTable.value.refresh()
  }

  watch([current_page, per_page, searchQuery], () => {
    refetchData()
  })

  const fetchExercises = (ctx, callback) => {
    store
      .dispatch('app-content/fetchExercises', {
        id: router.currentRoute.params.lesson_id,
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then(response => {
        const { data: exercises, total } = response.data.data

        callback(exercises)
        totalExercises.value = total
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching exercises list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const storeExercise = () => {
    const formData = new FormData()
    formData.append('lesson_id', router.currentRoute.params.lesson_id)
    formData.append('type', addExerciseType.value)
    switch (addExerciseType.value) {
      case 'Group Exercise':
        formData.append('instruction', addGroupExerciseInstruction.value)
        formData.append('activity', addGroupExerciseActivity.value)
        formData.append('requires_response', addGroupExerciseRequiresResponse.value)
        formData.append('expected_response', addGroupExerciseExpectedResponse.value)
        formData.append('translation[instruction]', addGroupExerciseInstructionTranslation.value)
        formData.append('translation[activity]', addGroupExerciseActivityTranslation.value)
        formData.append('translation[expected_response]', addGroupExerciseExpectedResponseTranslation.value)
        break
      case 'Information':
        formData.append('topic', addInformationTopic.value)
        formData.append('subtopic', addInformationSubtopic.value)
        formData.append('content', addInformationContent.value)
        formData.append('translation[topic]', addInformationTopicTranslation.value)
        formData.append('translation[subtopic]', addInformationSubtopicTranslation.value)
        formData.append('translation[content]', addInformationContentTranslation.value)
        break
      case 'Conversation':
        formData.append('conversation', addConversation.value)
        formData.append('translation[conversation]', addConversationTranslation.value)
        break
      case 'Match Word':
        formData.append('instruction', addMatchWordInstruction.value)
        formData.append('words', addMatchWordWords.value)
        formData.append('options', addMatchWordOptions.value)
        formData.append('answer', addMatchWordAnswer.value)
        formData.append('translation[instruction]', addMatchWordInstructionTranslation.value)
        formData.append('translation[words]', addMatchWordWordsTranslation.value)
        formData.append('translation[options]', addMatchWordOptionsTranslation.value)
        formData.append('translation[answer]', addMatchWordAnswerTranslation.value)
        break
      case 'Question':
        formData.append('question', addQuestion.value)
        formData.append('options', addQuestionOptions.value)
        formData.append('answer', addQuestionAnswer.value)
        formData.append('translation[question]', addQuestionTranslation.value)
        formData.append('translation[options]', addQuestionOptionsTranslation.value)
        formData.append('translation[answer]', addQuestionAnswerTranslation.value)
        break
      default:
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error uploading exercise',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        break
    }

    // Add media
    formData.append('audio', addExerciseAudio.value)
    formData.append('image', addExerciseImage.value)
    formData.append('video', addExerciseVideo.value)

    store
      .dispatch('app-content/addExercise', formData)
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Exercise Added.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        addExerciseModal.value = false

        addTranslation.value = 'no'
        addExerciseAudio.value = null
        addExerciseImage.value = null
        addExerciseVideo.value = null

        switch (addExerciseType.value) {
          case 'Group Exercise':
            addGroupExerciseInstruction.value = ''
            addGroupExerciseActivity.value = ''
            addGroupExerciseRequiresResponse.value = 'No'
            addGroupExerciseExpectedResponse.value = ''
            addGroupExerciseInstructionTranslation.value = ''
            addGroupExerciseActivityTranslation.value = ''
            addGroupExerciseExpectedResponseTranslation.value = ''
            break
          case 'Information':
            addInformationTopic.value = ''
            addInformationSubtopic.value = ''
            addInformationContent.value = ''
            addInformationTopicTranslation.value = ''
            addInformationSubtopicTranslation.value = ''
            addInformationContentTranslation.value = ''
            addExerciseAudio.value = null
            addExerciseImage.value = null
            addExerciseVideo.value = null
            break
          case 'Conversation':
            addConversation.value = ''
            addConversationTranslation.value = ''
            break
          case 'Match Word':
            addMatchWordInstruction.value = ''
            addMatchWordWords.value = ''
            addMatchWordOptions.value = ''
            addMatchWordAnswer.value = ''
            addMatchWordInstructionTranslation.value = ''
            addMatchWordWordsTranslation.value = ''
            addMatchWordOptionsTranslation.value = ''
            addMatchWordAnswerTranslation.value = ''
            break
          case 'Question':
            addQuestion.value = ''
            addQuestionOptions.value = ''
            addQuestionAnswer.value = ''
            addQuestionTranslation.value = ''
            addQuestionOptionsTranslation.value = ''
            addQuestionAnswerTranslation.value = ''
            break
          default:
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error clearing fields exercise',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            break
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred while adding the exercise.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const storeExerciseAfterAnotherExercise = () => {
    const formData = new FormData()
    formData.append('lesson_id', router.currentRoute.params.lesson_id)
    formData.append('type', addExerciseType.value)
    formData.append('previous_exercise_id', prevExerciseId.value)
    formData.append('placement', exercisePlacement.value)
    switch (addExerciseType.value) {
      case 'Group Exercise':
        formData.append('instruction', addGroupExerciseInstruction.value)
        formData.append('activity', addGroupExerciseActivity.value)
        formData.append('requires_response', addGroupExerciseRequiresResponse.value)
        formData.append('expected_response', addGroupExerciseExpectedResponse.value)
        formData.append('translation[instruction]', addGroupExerciseInstructionTranslation.value)
        formData.append('translation[activity]', addGroupExerciseActivityTranslation.value)
        formData.append('translation[expected_response]', addGroupExerciseExpectedResponseTranslation.value)
        break
      case 'Information':
        formData.append('topic', addInformationTopic.value)
        formData.append('subtopic', addInformationSubtopic.value)
        formData.append('content', addInformationContent.value)
        formData.append('translation[topic]', addInformationTopicTranslation.value)
        formData.append('translation[subtopic]', addInformationSubtopicTranslation.value)
        formData.append('translation[content]', addInformationContentTranslation.value)
        break
      case 'Conversation':
        formData.append('conversation', addConversation.value)
        formData.append('translation[conversation]', addConversationTranslation.value)
        break
      case 'Match Word':
        formData.append('instruction', addMatchWordInstruction.value)
        formData.append('words', addMatchWordWords.value)
        formData.append('options', addMatchWordOptions.value)
        formData.append('answer', addMatchWordAnswer.value)
        formData.append('translation[instruction]', addMatchWordInstructionTranslation.value)
        formData.append('translation[words]', addMatchWordWordsTranslation.value)
        formData.append('translation[options]', addMatchWordOptionsTranslation.value)
        formData.append('translation[answer]', addMatchWordAnswerTranslation.value)
        break
      case 'Question':
        formData.append('question', addQuestion.value)
        formData.append('options', addQuestionOptions.value)
        formData.append('answer', addQuestionAnswer.value)
        formData.append('translation[question]', addQuestionTranslation.value)
        formData.append('translation[options]', addQuestionOptionsTranslation.value)
        formData.append('translation[answer]', addQuestionAnswerTranslation.value)
        break
      default:
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error uploading exercise',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        break
    }

    // Add media
    formData.append('audio', addExerciseAudio.value)
    formData.append('image', addExerciseImage.value)
    formData.append('video', addExerciseVideo.value)

    store
      .dispatch('app-content/addExerciseAfterAnotherExercise', formData)
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Exercise Added.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        showModalToAddExerciseAfterAnotherExercise.value = false

        prevExerciseId.value = ''
        exercisePlacement.value = ''

        addTranslation.value = 'no'
        addExerciseAudio.value = null
        addExerciseImage.value = null
        addExerciseVideo.value = null

        switch (addExerciseType.value) {
          case 'Group Exercise':
            addGroupExerciseInstruction.value = ''
            addGroupExerciseActivity.value = ''
            addGroupExerciseRequiresResponse.value = 'No'
            addGroupExerciseExpectedResponse.value = ''
            addGroupExerciseInstructionTranslation.value = ''
            addGroupExerciseActivityTranslation.value = ''
            addGroupExerciseExpectedResponseTranslation.value = ''
            break
          case 'Information':
            addInformationTopic.value = ''
            addInformationSubtopic.value = ''
            addInformationContent.value = ''
            addInformationTopicTranslation.value = ''
            addInformationSubtopicTranslation.value = ''
            addInformationContentTranslation.value = ''
            addExerciseAudio.value = null
            addExerciseImage.value = null
            addExerciseVideo.value = null
            break
          case 'Conversation':
            addConversation.value = ''
            addConversationTranslation.value = ''
            break
          case 'Match Word':
            addMatchWordInstruction.value = ''
            addMatchWordWords.value = ''
            addMatchWordOptions.value = ''
            addMatchWordAnswer.value = ''
            addMatchWordInstructionTranslation.value = ''
            addMatchWordWordsTranslation.value = ''
            addMatchWordOptionsTranslation.value = ''
            addMatchWordAnswerTranslation.value = ''
            break
          case 'Question':
            addQuestion.value = ''
            addQuestionOptions.value = ''
            addQuestionAnswer.value = ''
            addQuestionTranslation.value = ''
            addQuestionOptionsTranslation.value = ''
            addQuestionAnswerTranslation.value = ''
            break
          default:
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error clearing fields exercise',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            break
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred while adding the exercise.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateExercise = () => {
    const formData = new FormData()
    formData.append('exercise_id', exerciseData.value.id)
    formData.append('type', exerciseData.value.type)
    switch (exerciseData.value.type) {
      case 'GroupExercise':
        formData.append('instruction', editGroupExerciseInstruction.value)
        formData.append('activity', editGroupExerciseActivity.value)
        formData.append('requires_response', editGroupExerciseRequiresResponse.value)
        formData.append('expected_response', editGroupExerciseExpectedResponse.value)
        formData.append('translation[instruction]', editGroupExerciseInstructionTranslation.value)
        formData.append('translation[activity]', editGroupExerciseActivityTranslation.value)
        formData.append('translation[expected_response]', editGroupExerciseExpectedResponseTranslation.value)
        break
      case 'Information':
        formData.append('topic', editInformationTopic.value)
        formData.append('subtopic', editInformationSubtopic.value)
        formData.append('content', editInformationContent.value)
        formData.append('translation[topic]', editInformationTopicTranslation.value)
        formData.append('translation[subtopic]', editInformationSubtopicTranslation.value)
        formData.append('translation[content]', editInformationContentTranslation.value)
        break
      case 'Conversation':
        formData.append('conversation', editConversation.value)
        formData.append('translation[conversation]', editConversationTranslation.value)
        break
      case 'MatchWord':
        formData.append('instruction', editMatchWordInstruction.value)
        formData.append('words', editMatchWordWords.value)
        formData.append('options', editMatchWordOptions.value)
        formData.append('answer', editMatchWordAnswer.value)
        formData.append('translation[instruction]', editMatchWordInstructionTranslation.value)
        formData.append('translation[words]', editMatchWordWordsTranslation.value)
        formData.append('translation[options]', editMatchWordOptionsTranslation.value)
        formData.append('translation[answer]', editMatchWordAnswerTranslation.value)
        break
      case 'Question':
        formData.append('question', editQuestion.value)
        formData.append('options', editQuestionOptions.value)
        formData.append('answer', editQuestionAnswer.value)
        formData.append('translation[question]', editQuestionTranslation.value)
        formData.append('translation[options]', editQuestionOptionsTranslation.value)
        formData.append('translation[answer]', editQuestionAnswerTranslation.value)
        break
      default:
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating exercise',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        break
    }

    formData.append('audio', editExerciseAudio.value)
    formData.append('image', editExerciseImage.value)
    formData.append('video', editExerciseVideo.value)

    store
      .dispatch('app-content/updateExercise', formData)
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Exercise.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        editExerciseModal.value = false
        switch (exerciseData.value.type) {
          case 'GroupExercise':
            editGroupExerciseInstruction.value = ''
            editGroupExerciseActivity.value = ''
            editGroupExerciseRequiresResponse.value = 'No'
            editGroupExerciseExpectedResponse.value = ''
            editGroupExerciseInstructionTranslation.value = ''
            editGroupExerciseActivityTranslation.value = ''
            editGroupExerciseExpectedResponseTranslation.value = ''
            break
          case 'Information':
            editInformationTopic.value = ''
            editInformationSubtopic.value = ''
            editInformationContent.value = ''
            editInformationTopicTranslation.value = ''
            editInformationSubtopicTranslation.value = ''
            editInformationContentTranslation.value = ''
            break
          case 'Conversation':
            editConversation.value = ''
            editConversationTranslation.value = ''
            break
          case 'MatchWord':
            editMatchWordInstruction.value = ''
            editMatchWordWords.value = ''
            editMatchWordOptions.value = ''
            editMatchWordAnswer.value = ''
            editMatchWordInstructionTranslation.value = ''
            editMatchWordWordsTranslation.value = ''
            editMatchWordOptionsTranslation.value = ''
            editMatchWordAnswerTranslation.value = ''
            break
          case 'Question':
            editQuestion.value = ''
            editQuestionOptions.value = ''
            editQuestionAnswer.value = ''
            editQuestionTranslation.value = ''
            editQuestionOptionsTranslation.value = ''
            editQuestionAnswerTranslation.value = ''
            break
          default:
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error clearing fields exercise',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            break
        }

        editExerciseAudio.value = null
        editExerciseImage.value = null
        editExerciseVideo.value = null
        editTranslation.value = 'no'
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred while updating the exercise details.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteExercise = id => {
    deleteExerciseId.value = id
    store.dispatch('app-content/deleteExercise', deleteExerciseId.value)
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Exercise Deleted.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred while deleting the exercise.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const storeExerciseGuide = () => {
    const formData = new FormData()
    formData.append('exercise_id', addExerciseIdGuide.value)
    formData.append('guide', addExerciseGuide.value)
    formData.append('translation[guide]', addExerciseGuideTranslation.value)

    store.dispatch('app-content/addExerciseGuide', formData)
      .then(() => {
        refetchData()
        addExerciseGuideModal.value = false
        addExerciseIdGuide.value = ''
        addExerciseGuide.value = ''
        addGuideTranslation.value = 'no'
        addExerciseGuideTranslation.value = ''
        toast({
          component: ToastificationContent,
          props: {
            title: 'Added Exercise Guide.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateExerciseGuide = () => {
    const formData = new FormData()
    formData.append('exercise_id', editExerciseGuideId.value)
    formData.append('guide', editExerciseGuide.value)
    formData.append('translation[guide]', editExerciseGuideTranslation.value)

    store.dispatch('app-content/updateExerciseGuide', { id: editExerciseGuideId.value, data: formData })
      .then(() => {
        refetchData()
        editExerciseGuideModal.value = false
        editExerciseGuideId.value = ''
        editExerciseGuide.value = ''
        editGuideTranslation.value = 'no'
        editExerciseGuideTranslation.value = ''
        toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Exercise Guide.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchExercises,
    storeExercise,
    updateExercise,
    tableColumns,
    per_page,
    current_page,
    totalExercises,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refExercisesListTable,
    refetchData,

    addExerciseModal,
    addExerciseLessonId,
    addExerciseType,
    addTranslation,

    editExerciseModal,
    editTranslation,

    // Group Exercise
    addGroupExerciseInstruction,
    addGroupExerciseActivity,
    addGroupExerciseRequiresResponse,
    addGroupExerciseExpectedResponse,

    addGroupExerciseInstructionTranslation,
    addGroupExerciseActivityTranslation,
    addGroupExerciseExpectedResponseTranslation,

    editGroupExerciseInstruction,
    editGroupExerciseActivity,
    editGroupExerciseRequiresResponse,
    editGroupExerciseExpectedResponse,

    editGroupExerciseInstructionTranslation,
    editGroupExerciseActivityTranslation,
    editGroupExerciseExpectedResponseTranslation,

    // Information
    addInformationTopic,
    addInformationSubtopic,
    addInformationContent,

    addInformationTopicTranslation,
    addInformationSubtopicTranslation,
    addInformationContentTranslation,

    editInformationTopic,
    editInformationSubtopic,
    editInformationContent,

    editInformationTopicTranslation,
    editInformationSubtopicTranslation,
    editInformationContentTranslation,

    // Conversation
    addConversation,
    addConversationTranslation,

    editConversation,
    editConversationTranslation,

    // Match Word
    addMatchWordInstruction,
    addMatchWordWords,
    addMatchWordOptions,
    addMatchWordAnswer,

    addMatchWordInstructionTranslation,
    addMatchWordWordsTranslation,
    addMatchWordOptionsTranslation,
    addMatchWordAnswerTranslation,

    editMatchWordInstruction,
    editMatchWordWords,
    editMatchWordOptions,
    editMatchWordAnswer,

    editMatchWordInstructionTranslation,
    editMatchWordWordsTranslation,
    editMatchWordOptionsTranslation,
    editMatchWordAnswerTranslation,

    // Question
    addQuestion,
    addQuestionOptions,
    addQuestionAnswer,

    addQuestionTranslation,
    addQuestionOptionsTranslation,
    addQuestionAnswerTranslation,

    editQuestion,
    editQuestionOptions,
    editQuestionAnswer,

    editQuestionTranslation,
    editQuestionOptionsTranslation,
    editQuestionAnswerTranslation,

    // Audio
    addExerciseAudio,
    editExerciseAudio,

    // Video
    addExerciseVideo,
    editExerciseVideo,

    // Image
    addExerciseImage,
    editExerciseImage,

    exerciseData,

    // Add Exercise Guide
    addExerciseGuideModal,
    addExerciseIdGuide,
    addExerciseGuide,
    addGuideTranslation,
    addExerciseGuideTranslation,

    // Edit Exercise Guide
    editExerciseGuideModal,
    editExerciseGuideId,
    editExerciseGuide,
    editGuideTranslation,
    editExerciseGuideTranslation,

    storeExerciseGuide,
    updateExerciseGuide,

    showModalToAddExerciseAfterAnotherExercise,
    exercisePlacement,
    prevExerciseId,
    storeExerciseAfterAnotherExercise,

    deleteExercise,
    deleteExerciseId,
  }
}
